import React, { useEffect, useState } from "react";
import { Row, Col, Table, Button, Container } from "reactstrap";

import MetaTags from "react-meta-tags";

import { Link, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DetailRow } from "../utils";
import { fetchDataset, fetchImage } from "../../tools/api"

export const ImagePage = (props) => {
  let params = useParams();

  let defs = require("../../assets/txt/definitions.json");
  const [isFavorite, setIsFavorite] = useState(false);
  const [did] = useState(params.did);
  const [iid] = useState(params.iid);
  const [imageData, setimageData] = useState({
    img_id: 0,
    did: 0,
    kvals: {},
    path_path: "",
    markerColors: {},
    page_title_meta: "",
    markers: [],
    relevantKeys: [],
    isLoaded: false,
  });
  const [datasetKvals, setDatasetKvals] = useState({
    dsName: "",
    kvals: {},
  });

  const doi_url = "https://doi.org/" + imageData.kvals["Image info - DOI"];

  useEffect(() => {
    async function fetchData() {
      try {
        const imageData = await fetchImage(iid);
        if (Object.keys(imageData.kvals).length > 0) {
          let kvals = {};
          for (const key in imageData.kvals) {
            kvals[key] = imageData.kvals[key].val;
          }
  
          setimageData({
            img_id: iid,
            did: imageData.did,
            kvals: kvals,
            path_path: imageData.pathpath,
            markerColors: imageData.channel_info,
            isLoaded: true,
            page_title_meta: imageData.page_title_meta,
            regPlane: imageData.did == "1129" ? imageData.kvals["Organ info - Organ"].val: imageData.reg_plane,
            markers: imageData.markers,
            relevantKeys: imageData.relevant_keys,
            img_src: `${process.env.REACT_APP_IMAGE_URL}/${imageData.did}/${imageData.iid}.jpg`
          });
        } else {
          setimageData({
            img_id: iid,
            kvals: { Error: "No information for this image" },
            path_path: imageData.pathpath,
          });
        }

        const datasetData = await fetchDataset(imageData.did);
        setDatasetKvals({
          dsName: datasetData.dsname,
          kvals: datasetData.kvals,
        });
  
        
  
        if (imageData !== undefined) {
          setIsFavorite(
            imageData !== undefined && props.favorites.includes(`${iid}`)
          );
        }
  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchData();
  
  }, []);

  const favorite = () => {
    setIsFavorite(!isFavorite);
    props.favoriteCallback([[imageData.img_id, imageData.did]]);
  };

  let tinycolor = require("tinycolor2");
  let labelRe = /^(?!Hex code)([a-zA-Z]+\s+info)?(\s+-\s+)?(.+)$/;

  return (
    <>
      <MetaTags>
        <title>{imageData.kvals.page_title_meta}</title>
        <meta
          name="description"
          content={`Image details for: ${imageData.kvals.page_title_meta}`}
        />
      </MetaTags>

      <Container className="image-detail-container">
        <div className="image-data-container">
          {
            <div className="image-data">
              <div className="cookiecrumb">
                Home {">"}{" "}
                <a href={`/datasets/${imageData.did}/explore`}>{datasetKvals.dsName}</a>
              </div>
              <div className="image-title">
                <h1>
                    {/* need to set HTML here to display bold values */}
                  {imageData.page_title_meta}
                </h1>
              </div>

              <Row className="image-page-top-panel rounded">
                <Col md="5" sm="12" xs="12">
                  <div className="page-image">
                    <Row>
                      <Col md="12" sm="12" style={{ padding: "0" }}>
                        <a
                          href={imageData.path_path}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="pathviewer-button"
                        >
                          <Button
                            className="btn-info btn-lg btn-block btn-open-pathviewer"
                            color="secondary"
                          >
                            Open in PathViewer{" "}
                            <FontAwesomeIcon
                              size="1x"
                              icon="external-link-alt"
                            />
                          </Button>
                        </a>

                        <a
                          href={imageData.path_path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={imageData.img_src}
                            alt={imageData.img_id}
                            className="page-image img-fluid"
                          />
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" sm="12" xs="12" style={{ padding: "0" }}>
                        <div className="image-markers">
                          Markers:
                          {Object.keys(imageData.markerColors)
                            .filter((key) => key !== "")
                            .map((marker) => (
                              <span
                                key={marker}
                                className="tag marker"
                                style={{
                                  color: tinycolor(
                                    imageData.markerColors[marker]).isLight() ? "#000000": "#FFFFFF", backgroundColor: `#${imageData.markerColors[marker]}`,
                                }}
                              >
                                {marker}
                              </span>
                            ))}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md="7" sm="12" xs="12">
                  <Row>
                    <Col md="6">&nbsp;</Col>
                    <Col md="6">
                      <div className="w-100 image-actions">
                        {!isFavorite && (
                          <Button
                            outline
                            color="success"
                            className="favorite btn btn-outline-info"
                            onClick={() => favorite()}
                          >
                            Save for later{" "}
                            <FontAwesomeIcon
                              icon={["far", "bookmark"]}
                              size="1x"
                            />
                          </Button>
                        )}
                        {isFavorite && (
                          <Button
                            outline
                            color="danger"
                            className="favorite btn btn-outline-info"
                            onClick={() => favorite()}
                          >
                            Remove from bookmarks{" "}
                            <FontAwesomeIcon
                              icon={["fas", "bookmark"]}
                              size="1x"
                            />
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Table id="table-image-page-top-panel">
                    <tbody>
                      <tr>
                        <th>Region and section</th>
                        <td>
                          <div
                            className={`${imageData.regPlane} section_plane`}
                          ></div>
                        </td>
                      </tr>
                      <tr>
                        <th>Collection</th>
                        <td>
                          <a href={`/datasets/${did}/overview`}>
                            {datasetKvals.dsName}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th>Image ID</th>
                        <td>{imageData.img_id}</td>
                      </tr>
                      <tr>
                        <th>Added on</th>
                        <td>{datasetKvals.kvals.release_date}</td>
                      </tr>
                      <tr>
                        <th>Contributor</th>
                        <td>{imageData.kvals["Image info - Contributor"]}</td>
                      </tr>
                      <tr>
                        <th>DOI</th>
                        {imageData.kvals["Image info - DOI"] !== undefined && imageData.kvals["Image info - DOI"] !== "" ? (
                          <td>
                            <a href={`${doi_url}`} target="_blank"
                            rel="noopener noreferrer">
                              {imageData.kvals["Image info - DOI"]}{" "}
                              <FontAwesomeIcon icon="external-link-alt" />
                            </a>
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Row>
                <Col md="12" sm="12">
                  <div className="text-left">
                    <p className="">
                      For descriptions of metadata fields and abbreviations,
                      click{" "}
                      <Link
                        to={"/nomenclature"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </Link>
                      .
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className="bottom-kvals-section">
                <Col md="6" sm="12">
                  <Table>
                    <thead>
                      <tr>
                        <th colSpan="2">
                          <h5>
                            <strong>Donor Information</strong>
                          </h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {imageData.relevantKeys
                        .filter((key) => key.includes("Donor info -"))
                        .map((key) => {
                          if (
                            key === "Donor info - Program ID" &&
                            imageData.kvals["Program ID link"] !== undefined
                          ) {
                            return (
                              <DetailRow
                                key={imageData.iid + imageData.kvals[key]}
                                data={imageData.kvals[key]}
                                link={imageData.kvals["Program ID link"]}
                                desc={
                                  defs["Image Tags"][key] === undefined
                                    ? null
                                    : defs["Image Tags"][key]["Description"]
                                }
                                heading={labelRe.exec(key)[3]}
                              />
                            );
                          }else{
                          return (
                            <DetailRow
                              key={imageData.kvals[key]}
                              data={imageData.kvals[key]}
                              desc={
                                defs["Image Tags"][key] !== undefined
                                  ? defs["Image Tags"][key]["Description"]
                                  : defs["Collection-specific Data"][key] !==
                                    undefined
                                  ? defs["Collection-specific Data"][key]["Description"]
                                  : null
                              }
                              heading={labelRe.exec(key)[3]}
                            />
                          );
                            }
                        })}
                    </tbody>
                  </Table>
                </Col>
                <Col md="6" sm="12">
                  <Table>
                    <thead>
                      <tr>
                        <th colSpan="2">
                          <h5>
                            <strong>Sample Information</strong>
                          </h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {imageData.relevantKeys
                        .filter(
                          (key) =>
                            key.includes("Sample info -") ||
                            key.includes("Image info -")
                        )
                        .map((key) => {
                          if (
                            key === "Donor info - Program ID" &&
                            imageData.kvals["Program ID link"] !== undefined
                          ) {
                            return (
                              <DetailRow
                                key={imageData.kvals[key]}
                                data={imageData.kvals[key]}
                                link={imageData.kvals["Program ID link"]}
                                desc={
                                  defs["Image Tags"][key] === undefined
                                    ? ""
                                    : defs["Image Tags"][key]["Description"]
                                }
                                heading={labelRe.exec(key)[3]}
                              />
                            );
                          }
                            return (
                              <DetailRow
                                key={imageData.kvals[key]}
                                data={imageData.kvals[key]}
                                desc={
                                  defs["Image Tags"][key] !== undefined
                                    ? defs["Image Tags"][key]["Description"]
                                    : defs["Collection-specific Data"][key] !==
                                      undefined
                                    ? defs["Collection-specific Data"][key][
                                        "Description"
                                      ]
                                    : null
                                }
                                heading={labelRe.exec(key)[3]}
                              />
                            );
                          
                        })}

                        {imageData.did == "1129" ?  <DetailRow
                              key={"Organ"}
                              data={imageData.kvals["Organ info - Organ"]}
                              heading={"Organ"}
                            />: null}
                      <DetailRow
                        key={"Markers"}
                        data={imageData.markers
                          .sort((a, b) => a.val.localeCompare(b.val))
                          .filter((marker) => marker.val !== "")
                          .map((marker) => marker.val)
                          .join(", ")}
                        desc={""}
                        heading={"Markers"}
                      />
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          }
        </div>
      </Container>
    </>
  );
  // }
};

export default ImagePage;
