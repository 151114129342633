import React from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap'

import Select from 'react-select';

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'

import Particles from 'react-particles-js'

import bannerImg from '../../assets/img/page_imgs/banner-bg3-fade.jpg'
// import illustrationAlt from '../../assets/img/page_imgs/pancreas-islet-cells.png'
// import illustration from '../../assets/img/page_imgs/pancreas-islet-cells.webp'






export default class Banner extends React.Component {
  
  constructor (props) {
    super(props)
    this.state = {
      modalOpen: false,
      userInput: '',
      errorText: false
    }
    this.handleUserInputChange = this.handleUserInputChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggle = this.toggle.bind(this)
    this.options = require('../../assets/txt/markers.json')
  }

  handleUserInputChange(event) {
    this.setState({
      userInput: event.value,
      errorText: false
    })
  }

  onSubmit(){
    this.setState({
      errorText: true
    })
    
  }

  toggle () {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  render () {
    return (
      <div>
        <div className='overlay'>
          <Particles
            params={{
              particles: {
                number: {
                  value: 50,
                  density: {
                    enable: true,
                    value_area: 1600
                  }
                },
                color: {
                  value: '#ffffff'
                },
                shape: {
                  type: 'circle',
                  stroke: {
                    width: 0,
                    color: '#000000'
                  },
                  polygon: {
                    nb_sides: 5
                  },
                  image: {
                    src: 'img/github.svg',
                    width: 100,
                    height: 100
                  }
                },
                opacity: {
                  value: 0.5,
                  random: false,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false
                  }
                },
                size: {
                  value: 10,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 1,
                    size_min: 0.1,
                    sync: false
                  }
                },
                line_linked: {
                  enable: false,
                  distance: 150,
                  color: '#ffffff',
                  opacity: 0.4,
                  width: 1
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: 'top-right',
                  random: false,
                  straight: false,
                  out_mode: 'out',
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200
                  }
                }
              },
              interactivity: {
                detect_on: 'window',
                events: {
                  onhover: {
                    enable: true,
                    mode: 'bubble'
                  },
                  onclick: {
                    enable: false,
                    mode: 'push'
                  },
                  resize: true
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1
                    }
                  },
                  bubble: {
                    distance: 400,
                    size: 12,
                    duration: 2,
                    opacity: 0.7,
                    speed: 3
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4
                  },
                  push: {
                    particles_nb: 4
                  },
                  remove: {
                    particles_nb: 2
                  }
                }
              },
              retina_detect: true
            }} style={{
              display: 'block',
              position: 'relative',
              zIndex: -1,
              width: '100%',
              height: '100%',
              background: `url(${bannerImg}) no-repeat center center fixed`,
              backgroundSize: 'cover'
            }}
            height={'calc(100vh - 70px)'}
          />
        </div>
        <Container className='banner-container'>
          <Row style={{ height: '33%' }} />
          <Row style={{ height: '33%', width:'100%' }}>
            <Col md='7' style={{display:'flex', justifyContent:'space-evenly'}}>
              <div style={{display:'flex', flexDirection:'column', position: 'relative',right: '4vw',alignSelf:'center'}}>
              <h1 className='banner-header'>Pancreatlas is a curated resource to share data from human pancreas samples.</h1>
              <h4 className='banner-subheader-left'>These datasets serve to document changes in the human pancreas from birth to adulthood, in health and disease.</h4>
              </div>
              {/* <Link to='/datasets'><Button className='banner-btn'color='info'size='lg' block>View our Collections</Button></Link> */}
            </Col>

            <Col md='5' className='d-none d-md-block search-container'>
              <div className='search-div'>
              <Select className='homepage-searchbox' options={this.options} placeholder="Search by Marker" onChange={this.handleUserInputChange}/>
              
              {/* disable link when user tries to submit without input */}
              {this.state.userInput !== '' ? <Link to={{pathname:"/explore-all-images", state:{userInput: this.state.userInput}}}>
              <Button color='info' className='search-button'><strong>Search</strong></Button> 
              </Link>: <span>
              <Button color='info' className='search-button' onClick={this.onSubmit}><strong>Search</strong></Button></span> }
              

              <p id="homepage_search_links" className='search-links'>Examples: <Link to={{pathname:"/explore-all-images", state:{userInput: 'PDX1'}}}>PDX1</Link>, <Link to={{pathname:"/explore-all-images", state:{userInput: 'GCG'}}}>GCG</Link>, <Link to={{pathname:"/explore-all-images", state:{userInput: 'INS'}}}>INS</Link></p>
              </div>
              {this.state.errorText ? <span style={{color:'red', padding:'1rem'}}>Please choose a marker</span>: null}
              <hr/>
              <div style={{backgroundColor:'rgba(255,255,255,0.9)', display:'flex', alignItems: 'center', justifyContent:'space-between', marginTop: '2rem', padding:'1rem', borderRadius:'5px',textAlign:'center'}}> 
              <h6 style={{alignSelf:'flex-end'}}>Don&apos;t know where to start?</h6><Link to='/explore-all-images'><Button outline color='info' className='mt-auto'>Browse all data</Button></Link>
              </div>
              <div style={{marginTop: '1rem', borderRadius:'5px', textAlign:'center'}} > <Link to='#' onClick={this.toggle}><FontAwesomeIcon size='1x' icon={faVideo} /> Take a 3-minute video tour of Pancreatlas</Link></div>
              

              <Modal style={{marginTop:'10%'}}isOpen={this.state.modalOpen} toggle={this.toggle} className='matrix-modal'>
              <ModalHeader toggle={this.toggle}>Pancreatlas Site Tour</ModalHeader>
              <ModalBody>
              <div>
                  <iframe
                    width="100%"
                    height="480"
                    src={`https://player.vimeo.com/video/672440786?h=adf4401e42`}
                    frameBorder="0"
                    title="Site tour"
                  />
                </div>
              </ModalBody>
            </Modal>
            </Col>
          </Row>
          <Row className='align-items-end' style={{ height: '33%' }}>
            <Col md='12' className='text-center'>
              <FontAwesomeIcon className='scroll-down-arrow' onClick={this.props.scrollDown} icon='angle-down' size='6x' color='white' />
            </Col>
          </Row>
          {/* <Row className='mt-4 justify-content-center' style={{ height: '15%' }}>
            <Col md='6'>
              <Link to={'/datasets'}><Button className='banner-btn' color='info' size='lg' block>View Our Collections</Button></Link>
            </Col>
          </Row> */}
          {/* <Row className='h-50 align-items-end'>
            <Col md='12' className='text-center'>
              <FontAwesomeIcon className='scroll-down-arrow' onClick={this.props.scrollDown} icon='angle-down' size='6x' color='white' />
            </Col>
          </Row> */}
        </Container>
      </div>
    )
  }
}
