import React from "react";
import ReactGA from "react-ga";
import { Container, Row, Button, ButtonGroup } from "reactstrap";

import MetaTags from "react-meta-tags";

import { NomenclatureSection, PageBanner } from "../utils";
import {
  getFirestore,
  collection,
  getDocs,
  onSnapshot,
} from "firebase/firestore";

const customOrder = [
  "Image Tags", 
  "Collection-specific Data", 
  "Programs", 
  "Disease Status", 
  "Markers", 
  "Stains", 
  "Sample Type_Processing", 
  "Section Plane", 
  "Pancreas Region", 
  "Imaging Modality"
];

export default class Nomenclature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchor: "",
      data: {}
    };
    
    
  }

  componentDidMount = () => {
    this.fetchDb();
    const hash = window.location.hash;
    const element = hash && document.getElementById(hash);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      this.setState({ anchor: hash.replace("#", "") });
    }
  };

  async fetchDb() {
    try {
      const db = getFirestore();
      const cachedData = localStorage.getItem("NomenclatureData");

      if (cachedData) {
        const data = JSON.parse(cachedData);
        this.setState({ data });
      } else {
        const snapshot = await getDocs(collection(db, "Nomenclature"));
        const data = {};

        snapshot.forEach((doc) => {
          const sectionName = doc.id;
          const terms = doc.data();
          data[sectionName] = terms;
        });

        localStorage.setItem("NomenclatureData", JSON.stringify(data));
        this.setState({ data });
      }

      onSnapshot(collection(db, "Nomenclature"), () => {
        this.updateData();
      });
    } catch (error) {
      console.log("Error fetching firebase data: ", error);
    }
  }

  async updateData() {
    // This method will be called whenever our Firestore data changes
    const db = getFirestore();
    try {
      const snapshot = await getDocs(collection(db, "Nomenclature"));
      const data = {};

      snapshot.forEach((doc) => {
        const sectionName = doc.id;
        const terms = doc.data();
        data[sectionName] = terms;
      });

      localStorage.setItem("NomenclatureData", JSON.stringify(data));
      this.setState({ data });
    } catch (error) {
      console.log("Error updating firebase data: ", error);
    }
  }

  

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="nomenclature">
        <MetaTags>
          <title>Nomenclature -- Pancreatlas</title>
          <meta
            name="description"
            content="How pancreatlas organizes its images"
          />
        </MetaTags>
        {/* <Container>
          <div style={{ height: '45vh' }}>
            <Row className='h-100'>
              <Col md='12' className='d-flex align-items-center'>
                <div className='dataset-title align-middle'>
                  <h1>Nomenclature</h1>
                  <p>Below are descriptions regarding the various annotations we have added to our images</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container> */}
        <PageBanner
          image
          bgImg={require("../../assets/img/headers/nomenclature-header.jpg")}
        >
          <h1>Nomenclature</h1>
          <p className="text-larger">
            Images contain metadata or &quot;tags&quot; that describe both the
            tissue sample (donor age, sex, disease phenotype, etc.) and the
            experimental modality (antigens visualized, imaging technology).
            Please refer to the tables below for descriptions of these
            attributes.
          </p>
        </PageBanner>
        <Container>
          <Row className="my-4">
            <ButtonGroup className="mt-4">
              <Button
                outline
                color="secondary"
                onClick={() => {this.setState({ open: false })
                window.location.hash = ""
              }}
                active={this.state.open === false}
              >
                Close All
              </Button>
              <Button
                outline
                color="secondary"
                onClick={() => {
                  this.setState({ open: true })
                }}
                active={this.state.open === true}
              >
                Open All
              </Button>
            </ButtonGroup>
          </Row>
          {Object.keys(this.state.data).sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b)).map((section) => (
            <div
              key={section}
              className="definitions"
              id={section.toLowerCase().replace(" ", "-").replace("/", "-")}
            >
              <NomenclatureSection
                data={this.state.data[section]}
                sectionName={section}
                openOverride={
                  section.toLowerCase().replace(" ", "-").replace("/", "-") === this.state.anchor || this.state.open
                  
                }
              />
            </div>
          ))}
        </Container>
      </div>
    );
  }
}
