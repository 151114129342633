import React from 'react'
import {
  Card,
  CardImg,
  Button
} from 'reactstrap'

import {
  Link
} from 'react-router-dom'

import ExploreAllDatasetCardImageWeb from "../../assets/img/logos/collection-card-all-banner.webp"
import ExploreAllDatasetCardImageJpg from "../../assets/img/logos/collection-card-all-banner.jpg"

export default class ExploreAllDatasetCard extends React.Component {
  constructor(props) {
    super(props)


    this.state = {
      logo: null,
      banner: '//via.placeholder.com/326x120'
    }
  }

  componentDidMount () {
    let dsBanner = null
    try {
      if (Modernizr.webp.alpha) {
        if(this.props.title === undefined){
          dsBanner = ExploreAllDatasetCardImageWeb
        } else{
          dsBanner = ExploreAllDatasetCardImageJpg
        }
        } 
}   catch (e) {
      console.log('Cannot find banner')
    }
    this.setState({
    
      banner: dsBanner != null ? dsBanner: '//via.placeholder.com/326x120'
    })
  
  }

  render() {
    // let sponsors = this.props.funding !== undefined ? this.props.funding.split(',').map(source => require(`../assets/${source}.jpg`)) : []
    /* global Modernizr */
   
    return (
        
      <div className='dataset-card h-100'>
        <Card className={'striped'} style={{height:'100%',backgroundColor:'#204356',color:'#fff'}}>

        <div style={{height:'100%'}}>
          
          {this.state.logo !== null && 
          <div className='dataset-card-heading' style={{ backgroundImage: `url(${this.state.banner})` }}>
            <div className='h-100 white-mask'><CardImg className='ds-logo' src={this.state.logo} alt='placeholder' />
            </div>
        </div>}
          {this.state.logo === null && 
          
          <div className='card-heading-text w-100 h-100' style={{  }}>
            <div style={{display:'flex', flexDirection:'column', paddingTop:'6rem', paddingBottom:'6rem'}}>
                <h1 className='full-width w-100'>{this.props.title}</h1>
                <p>{this.props.description}</p>
                <p>&nbsp;</p>
                <Link className='mt-auto' style={{width:'100%', display:'flex', justifyContent:'center'}} to={`/explore-all-images`}><Button color='light' size='lg' block>Start here!</Button></Link></div>
            </div>
            }
        </div>
        </Card>
      </div>
      
    )
  }
}
