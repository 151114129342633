import React from 'react'

import {
  Container
} from 'reactstrap'

export default class MaintenanceBanner extends React.Component {
  render () {
    return (
      <Container fluid className='maintenance-banner'>
        
        <h5>Pancreatlas will be down for scheduled maintenance starting on {this.props.dt}</h5>
        <p>We apologize for any inconvenience</p> 
      </Container>
    )
  }
}