import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_AUTH = process.env.REACT_APP_API_AUTH;

export const fetchDataset = async (did) => {
  try {
    const response = await axios.get(`${API_URL}/datasets/${did}`, {
      withCredentials: true,
      crossDomain: true,
      headers: {
        Authorization: API_AUTH,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch dataset:", error);
    throw error;
  }
};

export const fetchImage = async (iid) => {
  try {
    const response = await axios.get(`${API_URL}/images/${iid}`, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: API_AUTH,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch image:", error);
    throw error;
  }
};