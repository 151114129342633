import React from 'react'
import ReactGA from 'react-ga'
import {
  Container,
  Row,
  Col
} from 'reactstrap'

import {
  Link
} from 'react-router-dom'

import MetaTags from 'react-meta-tags'

import { PageBanner, TeamMember } from '../utils'
import {
  getFirestore,
  collection,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import MapPicture from '../../assets/img/page_imgs/map-collaborations.png'

export default class Contrubitors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
    
    
  }


  componentDidMount () {

    this.fetchDb();
    const hash = window.location.hash;
    const element = hash && document.getElementById(hash);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      this.setState({ anchor: hash.replace("#", "") });
    }
  }

  async fetchDb() {
    try {
      const db = getFirestore();
      const cachedData = localStorage.getItem("contribData");

      if (cachedData) {
        const data = JSON.parse(cachedData);
        this.setState({ data });
      } else {
        const snapshot = await getDocs(collection(db, "Contributors"));
        const data = {};

        snapshot.forEach((doc) => {
          const sectionName = doc.id;
          const terms = doc.data();
          data[sectionName] = terms;
        });

        localStorage.setItem("contribData", JSON.stringify(data));
        this.setState({ data });
      }

      onSnapshot(collection(db, "Contributors"), () => {
        this.updateData();
      });
    } catch (error) {
      console.log("Error fetching firebase data: ", error);
    }
  }

  async updateData() {
    // This method will be called whenever our Firestore data changes
    const db = getFirestore();
    try {
      const snapshot = await getDocs(collection(db, "Contributors"));
      const data = {};

      snapshot.forEach((doc) => {
        const sectionName = doc.id;
        const terms = doc.data();
        data[sectionName] = terms;
      });

      localStorage.setItem("contribData", JSON.stringify(data));
      this.setState({ data });
    } catch (error) {
      console.log("Error updating firebase data: ", error);
    }
  }

  render () {
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
      <div className='collaborators'>
        <MetaTags>
          <title>Contributors -- Pancreatlas</title>
          <meta name='description' content='Who is working together to create the HANDEL-P project?' />
        </MetaTags>
        <PageBanner image bgImg={require('../../assets/img/headers/contributors-header.jpg')}>
          <h1>Contributors</h1>
          <p className='text-larger'>The images displayed in our <Link className='link-light' to='/datasets'>Collections</Link> are the result of team-based efforts around the country. We are grateful to Pancreatlas investigators for their scientific contributions and support of this project.</p>
        </PageBanner>
        <Container className='v-padded conrib-container'>
          <h2 className='mb-3'><span>Meet our Contributors</span></h2>
          <div className="contrib-row mt-2">

          {Object.entries(this.state.data)
            .sort((a, b) => {
              const lastNameA = a[1].name.split(' ')[1] || '';  // Default to empty string if undefined
              const lastNameB = b[1].name.split(' ')[1] || '';
              return lastNameA.localeCompare(lastNameB);
            })
            .map(person => {
              return (
                <div className="contrib-col-md-4" key={person[1].name}>
                  <TeamMember
                    name={person[1].name}
                    institution={person[1].institution}
                    imgSrc={person[1].imgSrc}
                    site={person[1].site}
                    email={person[1].email}
                    phone={person[1].phone}
                  >
                    {person[1].desc}
                  </TeamMember>
                </div>
            )
          })}
          </div>
            </Container>

        <Container fluid className='shaded mt-4'>
          <Container fluid>
            <Container>
              <Row className='v-padded'>
                <Col md='6'>
                  <h1 className='py-4'>Creating Connections</h1>
                  <p style={{ fontSize: '1.5rem' }}>We are cross-pollinating numerous activities and programs to develop new ideas that accelerate diabetes research, education and clinical care.</p>
                  <p style={{ fontSize: '1.5rem' }}>If you are interested in collaborating with us &mdash; either by accessing tissue samples or contributing images &mdash; please <a href='mailto:pancreatlas@vumc.org'>get in touch</a>!</p>
                </Col>
                <Col md='6'>
                  <img src={MapPicture} alt='Contributors map' className='img-fill' />
                </Col>
              </Row>
            </Container>
          </Container>

        </Container>

      </div>
    )
  }
}
