import React, { Component } from 'react'
import './App.css'

import * as Sentry from '@sentry/browser'

import ReactGA from 'react-ga'

import {
  Button
} from 'reactstrap'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faLink, faEnvelope, faPhone, faGem, faMedkit, faUsers, faFlask, faVial, faHandPointer, faSearchPlus, faCopy, faPaperPlane, faExternalLinkAlt, faBookmark, faRedo, faBook, faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faBookmark as faBookmarkOutline } from '@fortawesome/free-regular-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

import { getRemoteConfig, fetchAndActivate, getBoolean, getString } from 'firebase/remote-config';


import {
  About,
  Admin,
  Contributors,
  Diabetes,
  DatasetListPage,
  Favorites,
  Home,
  Nomenclature,
  PageNotFound,
  Releases,
  Resources,
  Usage,
  Data
} from './components/pages'


import {
  AgeBrowser,
  DatasetOverview,
  GridView,
  PancreatlasFooter,
  MaintenanceBanner,
  TopNav,
  UserInfoModal,
  WarningBanner
} from './components/utils'

import { MatrixView } from './components/matrix-view' 
import { ImagePage } from './components/pages/ImagePage'

library.add(faLink, faEnvelope, faPhone, faGem, faMedkit, faUsers, faFlask, faVial, faHandPointer, faSearchPlus, faCopy, faPaperPlane, faExternalLinkAlt, faBookmark, faBookmarkOutline, faRedo, faBook, faAngleRight, faAngleDown, faTwitter)

class App extends Component {
  // blank line for new commit
  constructor(props) {
    super(props)

    Sentry.init({
      dsn: 'https://727efb032f954ff7baf2421cbcf2ace8@sentry.io/1412698',
      release: `pancreatlas@${process.env.REACT_APP_VERSION}`
    })

    // Initialize Google Analytics
    ReactGA.initialize('UA-140311900-1')
    ReactGA.pageview(window.location.pathname + window.location.search)

    this.addFavorite = this.addFavorite.bind(this)
    this.checkCompatability = this.checkCompatability.bind(this)
    this.addFavorite = this.addFavorite.bind(this)
    this.showInfoModal = this.showInfoModal.bind(this)
    this.dismissInfoModal = this.dismissInfoModal.bind(this)
    this.getCookie = this.getCookie.bind(this)

    let urllets = new URLSearchParams(window.location.search)

    let favs = []
    let encFavs = window.btoa(JSON.stringify(favs))
    if (urllets.has('iids')) {
      favs = JSON.parse(window.atob(urllets.get('iids')))
      encFavs = urllets.get('iids')
    }
    this.state = {
      favorites: favs,
      encodedFavorites: encFavs,
      error: null,
      userInfoDisplay: false,
      maintenanceActive: false,
      maintenanceDt: ""
    }
  }

  checkCompatability() {
    const { detect } = require('detect-browser')
    let browser = detect()
    let supported = true

    if (browser !== null) {
      switch (browser.name.toLowerCase()) {
        case 'firefox':
          if (parseInt(browser.version.split('.')[0], 10) < 50) {
            supported = false
          }
          break
        case 'chrome':
          if (parseInt(browser.version.split('.')[0], 10) < 55) {
            supported = false
          }
          break
        case 'ie':
        case 'safari':
        case 'opera':
        case 'edge':
          supported = false
          break
        default:
          supported = false
      }
    } else {
      supported = false
      browser = {
        name: 'Unknown',
        version: '-1'
      }
    }

    return { isSupported: supported, browserInfo: browser }
  }

  addFavorite(iid) {
    if (this.state.favorites.indexOf(iid[0][0]) !== -1 ) {
      let tmp = this.state.favorites
      tmp.splice(tmp.indexOf(iid[0][0]), (tmp.indexOf(iid[0][0])+2))
      this.setState({
        favorites: tmp,
        encodedFavorites: window.btoa(JSON.stringify(tmp))
      })
    } else {
      let tmp = this.state.favorites.concat(iid[0])
      this.setState({
        favorites: tmp,
        encodedFavorites: window.btoa(JSON.stringify(tmp))
      })
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)
  }

  getCookie(name) {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  dismissInfoModal() {
    this.setState({
      userInfoDisplay: false
    })
  }

  showInfoModal() {
    if (this.getCookie('feedback-sent') === undefined) {
      this.setState({
        userInfoDisplay: true
      })
    }
  }

  componentDidMount() {
    window.setTimeout(this.showInfoModal, 300000) // 5 minutes
    
    const remoteConfig = getRemoteConfig();

  fetchAndActivate(remoteConfig).then(() => {
    this.setState({
      maintenanceActive: getBoolean(remoteConfig, "maintenanceActive"),
      maintenanceDt: getString(remoteConfig, "maintenanceDt")
    });
});

  }

  render() {
    let supportInfo = this.checkCompatability()
    let supported = supportInfo.isSupported
    let browser = supportInfo.browserInfo
    let browserName = browser.name === 'ie' ? 'Internet Explorer' : browser.name
    let version = supportInfo.browserInfo.version

 
   
   
    
    // if (browser.name.toLowerCase() !== 'firefox' && browser.name.toLowerCase() !== 'chrome'){
    //   supported = false
    // }

    if (this.state.error) {
      return <Button onClick={() => Sentry.showReportDialog()}>Report feedback</Button>
    } else {
      return (
        <div>
          {supported === false && <WarningBanner><h5>Sorry, but your browser ({browserName.charAt(0).toUpperCase() + browserName.slice(1) + ' ' + version}) is not supported and some site features may not work properly.</h5><p>Please consider using the most recent versions of <a href='https://www.mozilla.org/en-US/firefox/new/'>Mozilla Firefox</a> or <a href='https://www.google.com/chrome'>Google Chrome</a>.</p></WarningBanner>}
          {this.state.maintenanceActive ? <MaintenanceBanner dt={this.state.maintenanceDt}></MaintenanceBanner>: null}
          {/* <Container fluid className='test-feedback'>
            <Row>
              <Col sm="12">
                <p><strong>N.B. This is a test version of the Pancreatlas.</strong></p>
              </Col>
            </Row>
          </Container> */}
          <Router>
            <div className='App'>
              <TopNav favorites={this.state.encodedFavorites} />
              <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/releases' component={Releases} />
                <Route path='/data' component={Data} />
                <Route path='/diabetes' component={Diabetes} />
                <Redirect from="/collaborators" to="/contributors"/>
                <Route path='/contributors' component={Contributors} />
                <Route path='/about' component={About} />

                <Route exact path={`/datasets`} component={DatasetListPage} />
                <Route exact path={`/datasets/:did/browse-by-age`} component={AgeBrowser} />
                <Route exact path={`/datasets/:did/explore`} render={(props) => <GridView {...props} favoriteCallback={this.addFavorite} favorites={JSON.parse(window.atob(this.state.encodedFavorites))} />} />
                <Route exact path={`/datasets/:did/explore/:iid`} render={(props) => <ImagePage {...props} favoriteCallback={this.addFavorite} favorites={JSON.parse(window.atob(this.state.encodedFavorites))} />} />
                <Route path={`/datasets/:did/overview`} component={DatasetOverview} />

                <Route exact path={`/explore-all-images`} render={(props) => <GridView {...props} favoriteCallback={this.addFavorite} favorites={JSON.parse(window.atob(this.state.encodedFavorites))} />} />
                <Route exact path={`/explore-all-images/:iid`} render={(props) => <ImagePage {...props} favoriteCallback={this.addFavorite} favorites={JSON.parse(window.atob(this.state.encodedFavorites))} />} />

                {/* <Route path='/pancreatlas/image/:iid' component={ImageDetail} /> */}
                <Route path='/matrixview/:dsid' component={MatrixView} />
                <Route path='/nomenclature' component={Nomenclature} />
                <Route path={`/favorites`} render={(props) => <Favorites {...props} favoriteCallback={this.addFavorite} favorites={JSON.parse(window.atob(this.state.encodedFavorites))} />} />
                <Route path='/resources' component={Resources} />
                <Route path='/admin' component={Admin} />
                <Route path='/data-usage' component={Usage} />
                <Route component={PageNotFound} />

              </Switch>
              <UserInfoModal visible={this.state.userInfoDisplay} toggle={this.dismissInfoModal} />
              <PancreatlasFooter />
            </div>
          </Router>
        </div>
      )
    }
  }
}

export default App
