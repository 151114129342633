import React from 'react'
import ReactGA from 'react-ga'
import {
  Container,
  Row,
  Col
} from 'reactstrap'

import { PageBanner } from '../utils'

import axios from 'axios'

export default class Releases extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      versions: {},
      loaded: false
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0);
    var releaseNotes = require('../../assets/txt/release_notes')
    var Remarkable = require('remarkable')
    var md = new Remarkable()
    for (let key of Object.keys(releaseNotes).sort().reverse()) {
      axios.get(releaseNotes[key]).then(resp => {
        var version = key.replace('_', '.')
        var newVersions = JSON.parse(JSON.stringify(this.state.versions))
        newVersions[version] = md.render(resp.data)
        this.setState({ versions: newVersions })
      })
    }
  }

  render () {
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
      <div className='release-info'>
        <PageBanner image bgImg={require('../../assets/img/headers/releases-header.jpg')}>
          <h1>Release History</h1>
          <p className='text-larger'>Find info about each of our releases here</p>
        </PageBanner>
        <Container>
          {Object.keys(this.state.versions).sort().reverse().map((version, index) => {
            if(index === 0){
              return(

                <Row key={index} className='py-4 rounded-lg border' style={{'padding': '2rem !important', 'backgroundColor': '#f6f6f6', 'borderColor': '#f6f6f6'}}>
                <Col md='12'  dangerouslySetInnerHTML={{ __html: this.state.versions[version] }} />
                </Row>
              )
            }else{
            return (
              <Row className='py-4 border-bottom' key={index}>
                <Col md='12' dangerouslySetInnerHTML={{ __html: this.state.versions[version] }} />
              </Row>
            )}
          })}
        </Container>
      </div>
    )
  }
}
