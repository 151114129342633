import React from 'react'
import {
  Table,
  Collapse
} from 'reactstrap'


import { faLink } from '@fortawesome/free-solid-svg-icons'

import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

export default class NomenclatureSection extends React.Component {
  constructor (props) {
    super(props)
    let asterisk = (Object.keys(this.props.data).includes('asterisk')) ? this.props.data['asterisk'] : undefined
    let blacklist = ['Image info - Pancreas Region', 'Image info - Section Plane']
    let rows = Object.entries(this.props.data)
    .sort((a, b) => {
        const [, valA] = a
        const [, valB] = b

        // If both have the order key, sort by the order key.
        if (valA.order !== undefined && valB.order !== undefined) {
            return valA.order - valB.order;
        }

        // If neither has the order key, sort alphabetically.
        return a[0].localeCompare(b[0]);
    })
    .map(([key]) => key)
    .filter(key => key !== 'asterisk' && !blacklist.includes(key));
    const allHeadings = Object.keys(this.props.data[rows[0]]);
    const specialHeadings = ['Description', 'Antigen description'].filter(key => allHeadings.includes(key))
    const otherHeadings = allHeadings.filter(key => !['image', 'order'].includes(key.toLowerCase()) && !specialHeadings.includes(key))


    this.copyToClipboard = this.copyToClipboard.bind(this)
    this.handleCollapse = this.handleCollapse.bind(this)

    this.state = {
      rows: rows,
      headings: [...specialHeadings, ...otherHeadings],
      open: false,
      asterisk: asterisk
    }
  }

  

  componentDidUpdate(prevProps) {
    if (prevProps.openOverride !== this.props.openOverride) {
      this.setState({ open: this.props.openOverride });
    } else if (!this.props.openOverride) {  
      const sectionsFromHash = window.location.hash.substring(1).split(',');
      const currentSectionID = this.props.sectionName.toLowerCase().replace(" ", "-").replace('/', '-');
      
      if (sectionsFromHash.includes(currentSectionID) && !this.state.open) {
        this.setState({ open: true });
      } else if (!sectionsFromHash.includes(currentSectionID) && this.state.open) {
        this.setState({ open: false });
      }
    }
  }
  


  copyToClipboard (){
    navigator.clipboard.writeText(window.location.href)
    .then(()=> {
      window.alert('Copied link to clipboard!')
    })
    .catch(() =>{
      window.alert('Something went wrong. Link not copied')
    })
    
  }

  handleCollapse = () =>{
    const sectionID = this.props.sectionName.toLowerCase().replace(" ", "-").replace('/', '-')

    const isOpen = !this.state.open
    this.setState({ open: isOpen })

    const currentSections = window.location.hash.substring(1).split(',')


    if (isOpen && !currentSections.includes(sectionID)) {
        currentSections.push(sectionID);
    } else {
        const index = currentSections.indexOf(sectionID);
        if (index > -1) {
            currentSections.splice(index, 1)
        }
    }
    const updatedSections = currentSections.filter(Boolean)
    window.location.hash = updatedSections.join(',')
  }

  

  render () {
    return (
      <div className='nomenclature-section'>
        <div className='nomenclature-section-header'>
       <span id={`#${this.props.sectionName.toLowerCase().replace(" ", "-").replace('/', '-')}`} className='nomenclature-collapse' onClick={() => this.handleCollapse()}><h3><FontAwesomeIcon className={this.state.open ? 'collapse-button collapse-button-open' : 'collapse-button collapse-button-closed'} icon='angle-right' />&nbsp;{this.props.sectionName.replace("_","/")}</h3></span>
       {this.state.open? <div className='faLink'><FontAwesomeIcon size='lg' style={{marginLeft: '2rem',marginTop: '7px', color:'gray', cursor: 'pointer'}} onClick= {() => {this.copyToClipboard()}} icon={faLink}/></div>: null}
       </div>
        <Collapse isOpen={this.state.open} >
          <Table className='my-4' hover>
            <thead>
              <tr className='table-header-row'>
                <th>Term</th>
                {this.state.headings.map(heading => heading !== 'order'? <th key={heading}>{heading}</th>:null)}
              </tr>
            </thead>
            <tbody>
              {this.state.rows.map(row => (
                <tr key={row}>
                  <td className='nomenclature-title'><strong>{row}</strong></td>
                  {this.state.headings.map(heading => {
                    if (heading in this.props.data[row]) {
                      if (heading.toLowerCase() === 'description' && 'Image' in this.props.data[row]) {
                        let img = require(`../../assets/img/${this.props.data[row]['Image']}`);
                        return (
                          <td key={heading} className='img-cell'>
                            <img className='nomenclature-img' src={img} alt='demo img' />
                            <p dangerouslySetInnerHTML={{ __html: this.props.data[row][heading] }} />
                          </td>
                        );
                      } else {
                        return (
                          <td key={heading} dangerouslySetInnerHTML={{ __html: this.props.data[row][heading] }} />
                        );
                      }
                    } else {
                      return <td key={heading}></td>; // Return an empty cell if data doesn't exist for that heading.
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
          {this.props.sectionName === "Collection-specific Data" ? <p><i>Cell types for the IIDP collection are quantified using 10 μm islet gel cryosections imaged at 20X; an automated cytonuclear algorithm (HALO, Indica Labs) is applied to each image and percentages represent a proportion of total detected cells. Presence of pathological features in the exocrine collection was assessed by two independent, blinded pathologists.</i></p> : null}
          {this.props.sectionName === "Sample Type/Processing" ? <p><i>See <a href='https://www.leicabiosystems.com/us/knowledge-pathway/fixation-and-fixatives-4-popular-fixative-solutions/' target='blank' rel='noreferrer noopener'>Popular Fixative Solutions</a> for information on histology fixatives.</i></p>: null}
          {this.state.asterisk !== undefined && <p><em>* {this.state.asterisk.description}</em></p>}
        </Collapse>
        <hr />
      </div>
    )
  }
}

NomenclatureSection.defaultProps = {
  data: {},
  sectionName: 'no name'
}
