import React from 'react'
import ReactGA from 'react-ga'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import {
  getFirestore,
  collection,
  getDocs,
  onSnapshot,
} from "firebase/firestore";

import MetaTags from 'react-meta-tags'

import { Link } from 'react-router-dom'

import { PageBanner } from '../utils'

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
    
    
  }


  componentDidMount = () => {
    this.fetchDb();
  };

  async fetchDb() {
    try {
      const db = getFirestore();
      const cachedData = localStorage.getItem("teamMembersData");

      if (cachedData) {
        const data = JSON.parse(cachedData);
        this.setState({ data });
      } else {
        const snapshot = await getDocs(collection(db, "Team_Members"));
        const data = {};

        snapshot.forEach((doc) => {
          const sectionName = doc.id;
          const people = doc.data();
          data[sectionName] = people;
        });

        const sortedData = Object.entries(data).sort((a, b) => {
          return a[1].order - b[1].order;
        });

        localStorage.setItem("teamMembersData", JSON.stringify(sortedData));
        this.setState({ data: sortedData });
      }

      onSnapshot(collection(db, "Team_Members"), () => {
        this.updateData();
      });
    } catch (error) {
      console.log("Error fetching firebase data: ", error);
    }
  }

  async updateData() {
    // This method will be called whenever our Firestore data changes
    const db = getFirestore();
    try {
      const snapshot = await getDocs(collection(db, "Team_Members"));
      const data = {};

      snapshot.forEach((doc) => {
        const sectionName = doc.id;
        const people = doc.data();
        data[sectionName] = people;
      });

      const sortedData = Object.entries(data).sort((a, b) => {
        return a[1].order - b[1].order;
      });

      localStorage.setItem("teamMembersData", JSON.stringify(sortedData));
      this.setState({ data: sortedData });
    } catch (error) {
      console.log("Error updating firebase data: ", error);
    }
  }

  render () {
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
      <div className='about'>
        <MetaTags>
          <title>About -- Pancreatlas</title>
          <meta name='description' content='Our goal is to organize and make accessible full-resolution images from a diverse array of human pancreas samples. By connecting images from several independent programs and efforts, we have created a practical resource to elevate and accelerate scientific study of the human pancreas.' />
        </MetaTags>
        {/* <Header heading="About" /> */}
        <PageBanner image bgImg={require('../../assets/img/headers/about-header.jpg')}>
          <h1>About Pancreatlas</h1>
          <p className='text-larger'>Our goal is to organize and make accessible full-resolution images from a diverse array of human pancreas samples. By connecting images from several independent programs and efforts, we have created a practical resource to elevate and accelerate scientific study of the human pancreas.</p>
          
        </PageBanner>
        <Container className='v-padded'>

          <Row className='mb-4'>
            <Col md='7'>
            <h2>Creation of Pancreatlas</h2>
              <div>
                <p>Since the human pancreas cannot be safely biopsied, the research community has long relied on pancreatic tissues from cadaveric donors to understand the molecular and cellular processes of pancreatic development and disease. Such studies generate a wealth of complex images from multiple experimental modalities; however, many of these images are condensed into small, static figures for publication and contain substantial data that cannot be included at all, thus never becoming widely available to the scientific community. Furthermore, comprehensive image datasets of the human pancreas over the lifespan are not available to investigators working to understand human diabetes or the origins of pancreatic cancer. The <a href='https://www.powersbrissovaresearch.org/'>Powers and Brissova Research Group</a> at Vanderbilt, one of several groups investigating the human pancreas, recognized these unmet needs for a comprehensive atlas of the human pancreas and more sophisticated management and dissemination of imaging data. Supported by The Leona M. and Harry B. Helmsley Charitable Trust, the Human Islet Research Network, the NIDDK, and the JDRF, a multidisciplinary team at Vanderbilt has developed the online resource Pancreatlas&trade;.</p>
                <p>The goal is to provide the scientific community with easily accessible, detailed, comprehensive images of the human pancreas with the hope that this will advance our understanding of diseases such as diabetes, pancreatic cancer, and pancreatitis. Pancreatlas was built on the principles of automation/scalability, customization, and ease of use. By housing images in <Link to='/datasets'>Collections</Link> we provide curated points of entry to large image data sets; our filtering menus allow users to view and refine images based on multiple variables of interest. Additionally, we have integrated <Link to='nomenclature'>metadata annotations</Link> into images to provide information about the human samples and to encourage standardized nomenclature within the field.</p>
              </div>
            </Col>
            <Col md='5'>
              <div style={{paddingTop: '4rem'}}>
                <a href="https://doi.org/10.1016/j.patter.2020.100120" target='blank' rel='noreferrer noopener'>
                  <img className='img-fluid img-thumbnail'  style={{padding: '1rem'}} src={require('../../assets/img/page_imgs/Pancreatlas-Patterns.png')} alt='Graphical abstract of the Pancreatlas paper published in Cell Patterns' />
                </a></div>
                <p style={{marginTop: '1rem'}}>Pancreatlas is in print! Check out our <strong><a href="https://doi.org/10.1016/j.patter.2020.100120" target='blank' rel='noreferrer noopener'>manuscript</a></strong> that describes the infrastructure, basic features, and future directions of the platform, published in the November 2020 issue of the Cell Journal <em>Patterns</em>.</p>

            </Col>

          </Row>
        </Container>
        <Container fluid className='shaded'>
          <Container>
            <Row className='mb-4'>
              <Col md='12'>
                <h2 className='mt-4'>The Pancreatlas Team at Vanderbilt</h2>
                {this.state.data.filter(person => !person[1].former).map(person =>{
                  return(
                  <Row key={person[1].name} className='mt-4 mb-4'>
                  <Col md='2' className='text-center'>
                    <img className='img-fluid rounded-circle team-member-img' src={person[1].img} alt={person[1].name} />
                  </Col>
                  <Col md='8' className='mb-auto mt-auto'>
                    <h4>{person[1].name}</h4>
                    <h6>{person[1].title}</h6>
                    <p dangerouslySetInnerHTML={{ __html: person[1].desc }}></p>
                  </Col>
                </Row>
                  )
                })}
                
              </Col>
            </Row>

            <Col md='12'>

                <h2 className='mt-4'>Former Team Members</h2>

                {this.state.data.filter(person => person[1].former).map(person =>{
                  return(
                  <Row key={person[1].name} className='mt-4 mb-4'>
                  <Col md='2' className='text-center'>
                    <img className='img-fluid rounded-circle team-member-img' src={person[1].img} alt={person[1].name} />
                  </Col>
                  <Col md='8' className='mb-auto mt-auto'>
                    <h4>{person[1].name}</h4>
                    <h6>{person[1].title}</h6>
                    <p dangerouslySetInnerHTML={{ __html: person[1].desc }}></p>
                  </Col>
                </Row>
                  )
                })}

            </Col>


          </Container>
        </Container>
        <Container>
          <Row className='my-4'>
            <Col md='12'>
              <h2>Acknowledgements</h2>
              <p>We thank <a href='https://helmsleytrust.org/'>The Leona M. and Harry B. Helmsley Charitable Trust</a>, the <a href='https://hirnetwork.org/'>Human Islet Research Network</a>, (DK104211, DK108120, DK112232, DK106755), the <a href='https://www.jdrf.org/'>JDRF</a>, and the <a href='https://www.vumc.org/diabetescenter/'>Vanderbilt Diabetes Center</a> and <a href='https://www.vumc.org/diabetescenter/DRTC'>Vanderbilt Diabetes Research and Training Center</a> (DK20593) for project support.</p>
              <p>Images were acquired in part through use of the <a href='http://cisrweb.mc.vanderbilt.edu/CISR/'> Vanderbilt Imaging Shared Resource</a> (supported by NIH grants CA68485, DK20593, DK58404, DK59637, and EY08126) and the <a href='https://labnodes.vanderbilt.edu/community/profile/id/2228'>Islet Procurement and Analysis Core</a> of the Vanderbilt Diabetes Research and Training Center (DK20593).</p>
              <p>We are grateful to the following users who provided feedback and/or beta testing of the website: Dr. Irina Kusmartseva and Maria Beery (University of Florida/<a href='https://www.jdrfnpod.org/contact-us/'>nPod</a>); Dr. Maria Golson (Johns Hopkins); <a href='https://medicine.iu.edu/faculty/26337/linnemann-amelia/'>Dr. Amelia Linnemann</a> (Indiana); Heather Durai, Radhika Aramandla, and Greg Poffenberger (VUMC)</p>
              <p>Pancreas and islet schematics on the homepage are courtesy of <strong>Rachel Chandler</strong>, a talented biomedical illustrator who worked with our team at Vanderbilt. <strong>Diane Saunders</strong> created the Pancreatlas logo.</p>
              <p>Pancreatlas depends on the OMERO-Plus and PathViewer software licensed from <a href='https://www.glencoesoftware.com'>Glencoe Software</a>. We are especially grateful to <strong>Dr. Jason Swedlow</strong> and <strong>Emil Rozbicki</strong> of Glencoe for their technical expertise and collaboration.</p>
            </Col>
          </Row>
        </Container>
        <Container fluid className='shaded'>
          <Container>
            <Row>
              <Col md='12' className='my-4'>
                <h2 className='my-4'>FAQ</h2>
                <h5><strong>Do I need an account to view/access images?</strong></h5>
                <p className='mb-4'>No registration is required to access images. We are committed to making data publicly available.</p>
                <h5><strong>Can I download images for analysis?</strong></h5>
                <p className='mb-4'>At this time, we do not have the capability to offer downloads. If you are interested in seeing this feature added in a future version of Pancreatlas, please send your feedback <a href='mailto:pancreatlas@vumc.org'>here</a>.</p>
                <h5><strong>Can I upload my own images to Pancreatlas?</strong></h5>
                <p className='mb-4'>Pancreatlas is a curated resource, and since our initial goal is to provide a reference for human pancreatic architecture, we have chosen to limit content at this time. If you have images that you feel would be appropriate, we would love to discuss the possibility of including them. Please <a href='mailto:diane.saunders@vumc.org'>get in touch</a> for more information.</p>
                <h5><strong>How do I reference Pancreatlas?</strong></h5>
                <p className='mb-4'>We appreciate you citing Pancreatlas so we can continue growing and serving the research community! Please include the URL (<a href="http://pancreatlas.org">http://pancreatlas.org</a>) and RRID (<a href=" https://dknet.org/data/record/nlx_144509-1/SCR_018567/resolver">RRID:SCR_018567</a>) or reference our manuscript: Saunders, D. C. et al. Pancreatlas: Applying an Adaptable Framework to Map the Human Pancreas in Health and Disease. Patterns 1, 100120 (2020). See also our <Link to='/data-usage'>Data Usage and Citation Policy</Link>.</p>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    )
  }
}