import React from "react"
import classnames from 'classnames'
import { usePagination, DOTS } from "./usePagination"

const Pagination = props => {
    const{
        onPageChange,
        totalCount,
        siblingCount = 2,
        currentPage,
        pageSize,
    } = props

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    })

    if(currentPage === 0 || paginationRange.length < 1){
        return null
    }

    const onNext = () =>{
        onPageChange(currentPage + 1)
    }

    const onPrevious= () =>{
        onPageChange(currentPage - 1)
    }

    let lastPage = paginationRange[paginationRange.length - 1]

    return(
        <ul className='pagination-container'>
            <li className={classnames('pagination-item', {disabled: currentPage === 1})} onClick={onPrevious}>
                <div className="arrow left"/>
            </li>
            {paginationRange.map(pageNumber => {

                if(pageNumber === DOTS){
                    return <li key={'dots'} className="pagination-item dots">&#8230;</li>
                }

                return (
                    <li className={classnames('pagination-item', {selected: pageNumber === currentPage})} onClick={() => onPageChange(pageNumber)} key={pageNumber}>
                        {pageNumber}
                    </li>
                );

            })}
            <li className={classnames('pagination-item', {disabled: currentPage === lastPage})} onClick={onNext}>
                <div className="arrow right" />
            </li>
        </ul>  
    )
}

export default Pagination