import React from 'react'
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardFooter
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class TeamMember extends React.Component {
  render () {
    return (
      <Card className='h-100 memberCard'>
        <CardBody className='d-flex flex-column memberCardBody'>
          { this.props.site.includes('pancreatlas.org')?<a href={this.props.site}><CardImg className="mb-2" src={this.props.imgSrc} /></a>:<a href={this.props.site}  rel='noreferrer noopener'><CardImg className="mb-2" src={this.props.imgSrc} /></a>}
          <CardTitle className='center-row collaborator-title'><h4>{this.props.name}</h4></CardTitle>
          <CardSubtitle className='center-row'>{this.props.institution}</CardSubtitle>
          <CardText className='mt-2' dangerouslySetInnerHTML={{ __html: this.props.children }}></CardText>
        </CardBody>
        <CardFooter className='d-flex justify-content-end memberCardSide'>
          {this.props.site !== "" ? <a className='mt-3' href={this.props.site} target='blank' rel='noreferrer noopener'><FontAwesomeIcon size='1x' icon='link' /></a>: null}
          {this.props.email !== "" ? <a className='mt-3' href={this.props.email}><FontAwesomeIcon size='1x' icon='envelope' /></a>: null}
          {this.props.phone !== "" ? <a className='mt-3' href={this.props.phone} ><FontAwesomeIcon size='1x' icon='phone' /></a>: null}
        </CardFooter>
      </Card>
    )
  }
}

TeamMember.defaultProps = {
  imgSrc: '//via.placeholder.com/350x237'
}
