import { useMemo } from "react"


export const DOTS = '...'

export const usePagination = ({
    totalCount,
    pageSize,
    siblingCount = -2,
    currentPage
}) => {
    const paginationRange = useMemo(() => {

        const totalPageCount = Math.ceil(totalCount / pageSize)

        const totalPageNumbers = siblingCount + 5

        if (totalPageNumbers >= totalPageCount){
            return range(1, totalPageCount) ;
        }


        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)

        //logic to determine if dots need to be shown (left,right,both)
        const showLeftDots = leftSiblingIndex > 2 
        const showRightDots = rightSiblingIndex < totalPageCount - 2

        const firstPageIndex = 1
        const lastPageIndex = totalPageCount

        //case 2 left dots

        if(!showLeftDots && showRightDots) {
            let leftItemCount = 3 + 2 * siblingCount
            let leftRange = range(1, leftItemCount)

            return [...leftRange, DOTS, totalPageCount]
        }
        
        //case 3 right dots

        if(showLeftDots && !showRightDots) {
            let rightItemCount = 3 +2 * siblingCount
            let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)

            return [firstPageIndex, DOTS, ...rightRange]
        }

        //case 4 show both dots

        if(showLeftDots && showRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex)

            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
        }


    },[totalCount, pageSize, siblingCount, currentPage])

    return paginationRange
}


const range = (start, end) => {
    let length = end - start + 1

return Array.from({length}, (_, idx) => idx + start)
}
