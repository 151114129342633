import React from 'react'
import ReactGA from 'react-ga'
import {
  Container,
  Row,
  Col,
  Table
} from 'reactstrap'

import PageBanner from '../utils/PageBanner'
import { getFirestore, collection, getDocs, onSnapshot } from 'firebase/firestore'

export default class Resources extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      data:{}
    }
  }

  componentDidMount=() =>{
    this.fetchDb()
  }

  async fetchDb(){
    const db = getFirestore()
    try{
        const cachedData = localStorage.getItem('resourcesData')

        if(cachedData){
            const data = JSON.parse(cachedData)
            this.setState({ data })
        } else {
            const snapshot = await getDocs(collection(db, 'Resources'));
            const data = {}

            snapshot.forEach(doc => {
                const sectionName = doc.id
                const terms = doc.data()
                data[sectionName] = terms
            })

            localStorage.setItem('resourcesData', JSON.stringify(data))
            this.setState({ data })
        }

        onSnapshot(collection(db, 'Resources'), () => {
            this.updateData()
        })
 
    } catch(error){
        console.log('Error fetching firebase data: ', error)
    }
}


  updateData = async () => {
    const db = getFirestore()
    try {
        const snapshot = await getDocs(collection(db, 'Resources'));
        const data = {}

        snapshot.forEach(doc => {
            const sectionName = doc.id
            const terms = doc.data()
            data[sectionName] = terms
        })

        localStorage.setItem('resourcesData', JSON.stringify(data))
        this.setState({ data })

    } catch(error) {
        console.log('Error updating firebase data: ', error)
    }
}








  render () {
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
      <div className='resources'>
        <PageBanner image bgImg={require('../../assets/img/headers/resources-header.jpg')}>
          <h1>Pancreas Research Efforts</h1>
          <p className='text-larger'>Linked below are major organizations and scientific consortia pursuing human pancreas and diabetes research. Please visit their webpages to learn more about how you can get involved.</p>
        </PageBanner>

        <Container>
          <p><em>Involved in a program that&apos;s not listed here? Please <a href='mailto:pancreatlas@vumc.org'>send us</a> a description and logo so we can add it to our list.</em></p>
          <Row>
            <Col md='12'>
              <Table>
                <tbody>
                  {Object.keys(this.state.data).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })).map((key) => {
                    const resource = this.state.data[key];
                    return (
                      <tr className='mb-4' key={resource.title}>
                        {resource.url !== '' && <td><a href={resource.url} target='_blank' rel='noopener noreferrer'><img className='img-fluid resource-img' src={resource.img} alt={resource.title} /></a></td>}
                        {resource.url === '' && <td><img className='img-fluid' src={resource.img} alt={resource.title} /></td>}
                        <td><h4><strong>{resource.title}</strong></h4><div dangerouslySetInnerHTML={{ __html: resource.desc }} /></td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
