import React, { useEffect, useRef } from 'react';
import './utils.css'

function BarChart() {
  const blockRef = useRef(null);

  useEffect(() => {
    if (!blockRef.current) return;

    const valueElems = blockRef.current.querySelectorAll('.chart-value');
    valueElems.forEach((valueElem) => {
      const valueText = valueElem.textContent;
      valueElem.parentElement.style.width = valueText;
    });

    //for tooltip
    // $(blockRef.current).tooltip();
  }, []);

  const categories = [
    "Category A", "Category B", "Category C", "Category D", "Category E", "Category F"
  ];

  const barValues = [8, 11, 34, 2, 32, 13];

  return (
    <figure>
      <div className="graphic">
        <div className="bar-chart-row">
          <h6 className='barchart-title'>Bar Title</h6>
          <div className="chart" ref={blockRef}>
            {barValues.map((value, index) => (
              <span 
                key={index} 
                className="chart-block" 
                title={categories[index]}
              >
                <span className="chart-value">{value}%</span>
              </span>
            ))}
          </div>
        </div>
      </div>

      <div className="x-axis">
        <ul className="legend">
          {categories.map(category => (
            <li key={category}>{category}</li>
          ))}
        </ul>
      </div>
    </figure>
  );
}

export default BarChart;
