import React from 'react'
import ReactGA from 'react-ga'
import {
  Container,
  Row,
  Col
} from 'reactstrap'

import MetaTags from 'react-meta-tags'

import { 
  Banner,
  DatasetList,
  Feature,
  SponsorLogo
} from '../utils'

import Helmsley from '../../assets/img/sponsor/helmsley.webp'
import HelmsleyAlt from '../../assets/img/sponsor/helmsley.jpg'

import HIRN from '../../assets/img/sponsor/hirn.webp'
import HIRNAlt from '../../assets/img/sponsor/hirn.jpg'

import IIAM from '../../assets/img/sponsor/iiam.webp'
import IIAMAlt from '../../assets/img/sponsor/IIAM.png'

import IIDP from '../../assets/img/sponsor/iidp.webp'
import IIDPAlt from '../../assets/img/sponsor/IIDP.png'

import NDRI from '../../assets/img/sponsor/ndri.webp'
import NDRIAlt from '../../assets/img/sponsor/NDRI.jpg'

import VUMC from '../../assets/img/sponsor/vumc.webp'
import VUMCAlt from '../../assets/img/sponsor/VUMC.png'




export default class Home extends React.Component {
  constructor (props) {
    super(props)
    this.aboutRef = React.createRef()

    this.scrollToAbout = this.scrollToAbout.bind(this)
  }

  scrollToAbout () {
    // console.log(this.myRef)
    window.scrollTo(0, this.aboutRef.current.offsetTop)
  }

  componentDidMount () {
    //console.log(this.myRef)
    
  }
  

  

  render () {
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
      <div className='home'>

        <MetaTags>
          <title>Pancreatlas - a curated resource to share data from human pancreas samples</title>
          <meta name='description' content='Pancreatlas contains datasets that serve to document changes in the human pancreas from birth to adulthood, in health and disease.' />
        </MetaTags>

        <Banner scrollDown={this.scrollToAbout} />
        <div className='dataset-list' ref={this.aboutRef}>
          <Container className='mt-4'>
            <h1 className='my-4 py-4'><strong>Image Collections</strong></h1>
            <DatasetList />
          </Container>
        </div>
        <Container fluid className='shaded'>
          <Container>
            <Row className='mb-4'>
              <div className='head-description v-padded'>
                <Row>
                  <Col md='12'>
                    <h2 className='section-heading'>Our Approach</h2>
                  </Col>
                </Row>
                <Row>
                  <Col md='12'>
                    <h5 className='section-desc'>Through detailed analysis of donor tissue, we hope to better understand events that trigger type 1 and type 2 diabetes. This website&mdash;an “atlas” of our findings&mdash;is designed to engage scientists worldwide and accelerate the discoveries in diabetes research.</h5>
                  </Col>
                </Row>
              </div>
              <Row className='mb-4'>
                <Col md='4'>
                  <Feature icon='users' heading='Team Science' description='Research groups at Vanderbilt University, the University of Florida, and Stanford University are leading synergistic, collaborative research efforts' />
                </Col>
                <Col md='4'>
                  <Feature icon='vial' heading='Systematic Methodology' description='We rely on high-resolution analysis of donor pancreatic tissues by immunohistochemistry, genomic approaches, and other functional techniques' />
                </Col>
                <Col md='4'>
                  <Feature icon='flask' heading='Fueling Discovery' description='Our goal is to fully describe pancreatic composition and architecture, informing the development of therapeutic interventions for disease' />
                </Col>
              </Row>
            </Row>
          </Container>
        </Container>
        <Container fluid>
          <div className='support v-padded'>
            <Row className='center-row my-4'>
              <Col sm='12'>
                <picture>
                  <source srcSet={Helmsley} type="image/webp" alt="Helmsley Trust" />
                  <img style={{ height: '8rem' }} className='img-fluid' src={HelmsleyAlt} alt='Helmsley Trust' />
                </picture>
              </Col>
            </Row>
            <Row className='center-row'>
              <Col sm='12'>
                <h4>This project is generously supported by <a href='https://helmsleytrust.org'>The Leona M. and Harry B. Helmsley Charitable Trust</a>.</h4>
              </Col>
            </Row>
            <Row className='center-row my-4 pt-4'>
              <Col sm='12'>
                <h4>We also thank the following:</h4>
              </Col>
            </Row>
            <Row className='justify-content-around align-items-center my-4 pb-4'>
              <Col lg={2} md={4} sm={6} className='d-flex justify-content-center'>
                <SponsorLogo location='https://www.iiam.org/' name='IIAM' webpSrc={IIAM} imgSrc={IIAMAlt} size='small' />
              </Col>
              <Col lg={2} md={4} sm={6} className='d-flex justify-content-center'>
                <SponsorLogo location='https://ndriresource.org/' name='NDRI' webpSrc={NDRI} imgSrc={NDRIAlt} size='small' />
              </Col>
              <Col lg={2} md={4} sm={6} className='d-flex justify-content-center'>
                <SponsorLogo location='https://iidp.coh.org/' name='IIDP' webpSrc={IIDP} imgSrc={IIDPAlt} size='small' />
              </Col>
              <Col lg={2} md={4} sm={6} className='d-flex justify-content-center'>
                <SponsorLogo location='https://hirnetwork.org/' name='HIRN' webpSrc={HIRN} imgSrc={HIRNAlt} size='small' />
              </Col>
              <Col lg={2} md={4} sm={6} className='d-flex justify-content-center'>
                <SponsorLogo location='https://www.vumc.org/vdrc/home' name='VUMC' webpSrc={VUMC} imgSrc={VUMCAlt} size='small' />
              </Col>
            </Row>
            <Row className='center-row my-4'>
              <Col sm='12'>
                <h4><em>We are extremely grateful to organ donors and their families.</em></h4>
              </Col>
            </Row>
          </div>
        </Container>
        
        
      </div>
    )
  }
}
