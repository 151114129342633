import { initializeApp } from 'firebase/app';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(config);
const auth = getAuth(app);
signInAnonymously(auth).catch((error) => {
  let errorCode = error.code;
  let errorMessage = error.message;
  console.error(`${errorCode}: ${errorMessage}`);
});

export const firebase_db = getFirestore(app);
export const remoteConfig = getRemoteConfig(app);
export const firebase_storage = getStorage(app);
remoteConfig.settings.minimumFetchIntervalMillis = 36000;

export const addNewUserInfo = (formInfo) => {
  const { detect } = require('detect-browser');
  let browser = detect();

  let data = {
    institution: formInfo.institution,
    role: formInfo.role,
    date_accessed: new Date(),
    browser_name: browser.name,
    browser_version: browser.version,
  };

  addDoc(collection(firebase_db, 'user_data'), data).catch((error) => {
    console.log("Error recording data" + error);
  });
};

export const fetchUserInfoRef = () => {
  return collection(firebase_db, 'user_data');
};

export default app;