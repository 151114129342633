import React from 'react'
import {
  Tooltip
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class DetailRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ttOpen: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      ttOpen: !this.state.ttOpen
    })
  }

  render() {
    if (this.props.data !== null && this.props.data !== undefined && this.props.data !== '') {
      let sanitizedHeading = this.props.heading.replace(/[^a-zA-Z0-9_-]/g, "").split(' ').join('_')
      return (
        <tr>
          <td className='image-detail-row'>
           <span id={`${sanitizedHeading}-tt`} style={{textAlign: 'left'}}>
              {this.props.desc !== "" ?  <Tooltip placement='left' isOpen={this.state.ttOpen} target={`${sanitizedHeading}-tt`} toggle={this.toggle}>{this.props.desc}</Tooltip>: null}
              <strong dangerouslySetInnerHTML={{__html: this.props.heading}}></strong>
            </span>
          </td>
          <td className='image-detail-row'>
            {/* {this.props.heading === "Collection"? <a href={`pancreatlas.org/datasets/${this.props.did}/overview`}>{this.props.data}</a>:this.props.did === undefined && <p>{this.props.data}</p>} */}
            {this.props.heading === "DOI" ? <a href={`https://doi.org/${this.props.data}`}>{this.props.data} <FontAwesomeIcon size='xs' icon='external-link-alt' /></a>:this.props.link === undefined && <span>{this.props.data}</span>}
            {this.props.link !== undefined && <span><a href={this.props.link} target='_blank' rel="noopener noreferrer"><u className='top-key-link'>{this.props.data}</u></a></span>}
          </td>

        </tr>)
    } else {
      return null
    }
  }
}

DetailRow.defaultProps = {
  desc: 'DEFAULT DESCRIPTION'
}
