import React from 'react'
import ReactGA from 'react-ga'
import {
  Container,
  Collapse,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table
} from 'reactstrap'

import MetaTags from 'react-meta-tags'

import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

import {
  Link,
  withRouter
} from 'react-router-dom'

import { Parallax } from 'react-parallax'

import axios from 'axios'

class DatasetOverview extends React.Component {
  constructor(props) {
    super(props)
    let re = /(\/\w+\/?)+([0-9]+)(\/\w+\/?)+/
    this.state = {
      title: 'Default Title',
      desc: 'Default desc',
      did: (re.exec(window.location.pathname) !== null) ? re.exec(window.location.pathname)[2] : 0,
      funders: [],
      imgs: [],
      titleImg: undefined,
      open: false,
      openalt: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios.get(`${process.env.REACT_APP_API_URL}/datasets/${this.state.did}`, {
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': process.env.REACT_APP_API_AUTH
      }
    }).then(result => {
      let imgs = result.data.kvals.imgs !== undefined ? result.data.kvals.imgs.split(',') : []
      let titleImgData = JSON.parse(result.data.kvals.title_img)
      let altImg = Object.prototype.hasOwnProperty.call(result.data.kvals,'altImg') ? JSON.parse(result.data.kvals.altImg): undefined
      this.setState({
        title: result.data.dsname,
        short_desc: result.data.kvals.description_short,
        long_desc: result.data.kvals.description_long,
        imgs: imgs,
        titleImg: titleImgData,
        sponsors: result.data.kvals.sponsor_text,
        release_original: result.data.kvals.release_original,
        release_updates: result.data.kvals.release_updates,
        refs: result.data.kvals.refs,
        imgTypes: result.data.kvals.img_types,
        imgCount: result.data.kvals.img_count,
        importFileLink: result.data.kvals.import_file,
        altImg: altImg
      })
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.openOverride !== this.props.openOverride && this.props.openOverride !== this.state.open) {
      this.setState({
        open: this.props.openOverride
      })
    }
    if (prevProps.openOverride !== this.props.openOverride && this.props.openOverride !== this.state.openalt) {
      this.setState({
        openalt: this.props.openOverride
      })
    }
  }


  render() {
    let logo = null
    if (this.state.title.toLowerCase() !== 'default title') {
      logo = require(`../../assets/img/datasets/${this.state.did}/${this.state.title.toLowerCase().replace(/ /g, '-').replace(/[^0-9a-zA-Z-_]/ig, '')}.jpg`)
    }
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
      <div className='datasetOverviewWrapper'>

        <MetaTags>
          <title>{this.state.title} -- Pancreatlas</title>
          <meta name='description' content={this.state.long_desc} />
        </MetaTags>

        <Parallax
          blur={0}
          bgImage={logo}
          bgImageAlt='Sample Image'
          strength={500}
        >
          <div className='parallax-filler' style={{ height: '30vh' }}>
            <Container className='h-100'>
              <Row className='h-100'>
                <Col md='12' className='d-flex align-items-center'>
                  <span className='dataset-title'><h1><strong>Collection: {this.state.title}</strong></h1>
                    <h3>
                      {this.state.short_desc}
                    </h3>
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </Parallax>
        <Container className='mt-4'>
          <Row className='mb-4 mt-4'>
            <Col md='12'>
              <Row className='my-4'>
                <Col md='6'>
                  <div className='dataset-description' dangerouslySetInnerHTML={{ __html: this.state.long_desc }} />
                </Col>
                <Col md='6'>
                  <div>
                    {this.state.titleImg !== undefined && <a href={this.state.titleImg.url}><img className='img-fluid dataset-picture' src={require(`../../assets/img/datasets/${this.state.did}/${this.state.titleImg.src}`)} alt='' /></a>}
                  </div>
                  <div className='mt-4 pt-4'>
                    <h4>Dataset Quick Reference</h4>
                  </div>

                  <Table>
                    <tbody>
                    <tr>
                      <th className="table-header-row">No. of Images</th>
                      <td><Link to={`/datasets/${this.state.did}/explore`}><Button className="btn-info btn-sm">{this.state.imgCount} </Button></Link></td>
                    </tr>
                    <tr>
                      <th>Initial Publication</th>
                      <td>{this.state.release_original}</td>
                    </tr>
                    {this.state.release_updates &&
                    <tr>
                      <th>Last Updated</th>
                      <td>{this.state.release_updates}</td>
                    </tr>
                    }
                    <tr>
                      <th>Image Type(s)</th>
                       <td>{this.state.imgTypes}</td>
                    </tr>
                    {this.state.refs &&
                        <tr>
                      <th>References</th>
                       <td dangerouslySetInnerHTML={{ __html: this.state.refs }}/>
                    </tr>
                    }
                    <tr>
                      <th>Source Import Data</th>
                      <td><a href={this.state.importFileLink} target='_blank' rel="noopener noreferrer">Download</a></td>
                    </tr>
                    </tbody>
                  </Table>
                  {this.state.altImg !== undefined && <img className='img-fluid dataset-picture' src={require(`../../assets/img/datasets/${this.state.did}/${this.state.altImg.src}`)} alt='' />}
                </Col>
                <Col md='12'>
                  {this.state.imgs.map((img) => {
                    return <img className='img-fluid' src={require(`../../assets/img/datasets/${this.state.did}/${img}`)} alt='' key={img}/>
                  })}
                </Col>
              </Row>
              {this.state.did === '960' ?
              <div className='nomenclature-section'>
              <span className='nomenclature-collapse' onClick={() => this.setState({ open: !this.state.open })}><h3><FontAwesomeIcon className={this.state.open ? 'collapse-button collapse-button-open' : 'collapse-button collapse-button-closed'} icon='angle-right' /><strong> Key Publications from AK Foulis</strong></h3></span>
              <Collapse isOpen={this.state.open}>
                    <ul>
                    <li>Foulis AK, Stewart JA (1984). The pancreas in recent-onset type 1 (insulin-dependent) diabetes mellitus: insulin content of islets, insulitis and associated changes in the exocrine acinar tissue. Diabetologia 26(6):456-461. <a href='http://www.ncbi.nlm.nih.gov/pubmed/6381192'>http://www.ncbi.nlm.nih.gov/pubmed/6381192</a></li>
                    <li>Foulis AK, Liddle CN, Farquharson MA, Richmond JA, Weir RS (1986). The histopathology of the pancreas in type 1 (insulin-dependent) diabetes mellitus: a 25-year review of deaths in patients under 20 years of age in the United Kingdom. Diabetologia 29(5):267-274. <a href='http://www.ncbi.nlm.nih.gov/pubmed/3522324'>http://www.ncbi.nlm.nih.gov/pubmed/3522324</a></li>
                    <li>Foulis AK, Farquharson MA (1986). Aberrant expression of HLA-DR antigens by insulin-containing beta-cells in recent-onset type I diabetes mellitus. Diabetes 35(11):1215-24. <a href='http://www.ncbi.nlm.nih.gov/pubmed/3530850'>http://www.ncbi.nlm.nih.gov/pubmed/3530850</a></li>
                    <li>Foulis AK, Farquharson MA, Hardman R (1987). Aberrant expression of class II major histocompatibility complex molecules by B cells and hyperexpression of class I major histocompatibility complex molecules by insulin containing islets in type 1 (insulin-dependent) diabetes mellitus. Diabetologia 30(5):333-343. <a href='http://www.ncbi.nlm.nih.gov/pubmed/3301484'>http://www.ncbi.nlm.nih.gov/pubmed/3301484</a></li>
                    <li>Foulis AK, Farquharson MA, Meager A (1987). Immunoreactive alpha-interferon in insulin-secreting beta cells in type 1 diabetes mellitus. Lancet 2(8573):1423-1427. <a href='http://www.ncbi.nlm.nih.gov/pubmed/2891993'>http://www.ncbi.nlm.nih.gov/pubmed/2891993</a></li>
                    <li>Foulis AK, McGill M, Farquharson MA (1991). Insulitis in type 1 (insulin-dependent) diabetes mellitus in man – macrophages, lymphocytes, and interferon-gamma containing cells. J Pathol. 165(2):97-103. <a href='http://www.ncbi.nlm.nih.gov/pubmed/1744803'>http://www.ncbi.nlm.nih.gov/pubmed/1744803</a></li>
                    <li>Foulis AK, Francis ND, Farquharson MA, Boylston A (1988). Massive synchronous B-cell necrosis causing type 1 (insulin-dependent) diabetes – a unique histopathological case report. Diabetologia 31(1):46-50. <a href='http://www.ncbi.nlm.nih.gov/pubmed/3127259'>http://www.ncbi.nlm.nih.gov/pubmed/3127259</a></li>
                    </ul>
                    </Collapse>
                    
                    <br/>
                    <br/>
                    
                    <span className='nomenclature-collapse' onClick={() => this.setState({ openalt: !this.state.openalt })}><h3><FontAwesomeIcon className={this.state.openalt ? 'collapse-button collapse-button-open' : 'collapse-button collapse-button-closed'} icon='angle-right' /><strong> Important Publications using the Recent-onset Type 1 Diabetes Cohort</strong></h3></span>
                    <Collapse isOpen={this.state.openalt} >
                    <ul>
                    <li>Willcox A, Richardson SJ, Bone AJ, Foulis AK, Morgan NG (2009). Analysis of islet inflammation in human type 1 diabetes. Clin Exp Immunol 155:173-181. <a href='http://www.ncbi.nlm.nih.gov/pubmed/19128359'>http://www.ncbi.nlm.nih.gov/pubmed/19128359</a></li>
                    <li>Richardson SJ, Willcox A, Bone AJ, Foulis AK, Morgan NG (2009). The prevalence of enteroviral capsid protein vp1 immunostaining in pancreatic islets in human type 1 diabetes. Diabetologia 52:1143-1151. <a href='http://www.ncbi.nlm.nih.gov/pubmed/19266182'>http://www.ncbi.nlm.nih.gov/pubmed/19266182</a></li>
                    <li>Richardson SJ, Willcox A, Bone AJ, Foulis AK, Morgan NG (2009). Islet-associated macrophages in type 2 diabetes. Diabetologia 52:1686-1688. <a href='http://www.ncbi.nlm.nih.gov/pubmed/19504085'>http://www.ncbi.nlm.nih.gov/pubmed/19504085</a></li>
                    <li>Willcox A, Richardson SJ, Bone AJ, Foulis AK, Morgan NG (2010). Evidence of increased islet cell proliferation in patients with recent-onset type 1 diabetes. Diabetologia 53:2020-2028. <a href='http://www.ncbi.nlm.nih.gov/pubmed/20532863'>http://www.ncbi.nlm.nih.gov/pubmed/20532863</a></li>
                    <li>Richardson SJ, Willcox A, Bone AJ, Morgan NG, Foulis AK (2011). Immunopathology of the Human Pancreas in Type-I Diabetes. Seminars in Immunopathology 33:9-21. <a href='http://www.ncbi.nlm.nih.gov/pubmed/20424842'>http://www.ncbi.nlm.nih.gov/pubmed/20424842</a></li>
                    <li>Willcox A, Richardson SJ, Bone AJ, Foulis AK, Morgan NG (2011). Immunohistochemical analysis of the relationship between islet cell proliferation and the production of the enteroviral capsid protein, VP1, in the islets of patients with recent-onset type 1 diabetes. Diabetologia 54:2417-2420. <a href='http://www.ncbi.nlm.nih.gov/pubmed/21597997'>http://www.ncbi.nlm.nih.gov/pubmed/21597997</a></li>
                    <li>Richardson SJ, Willcox A, Bone AJ, Morgan NG, Foulis AK (2013). Viruses in human pancreas. VIDIS Group Invited Book Chapter, ‘ Diabetes and Viruses’ Springer Book, p167-175.</li>
                    <li>Richardson SJ, Leete P, Bone AJ, Foulis AK, Morgan NG (2013). Expression of the enteroviral capsid protein VP1 in the islet cells of patients with type 1 diabetes is associated with induction of protein kinase R and downregulation of Mcl-1. Diabetologia 56(1):185-193. <a href='http://www.ncbi.nlm.nih.gov/pubmed/23064357'>http://www.ncbi.nlm.nih.gov/pubmed/23064357</a></li>
                    <li>Campbell-Thompson M, Atkinson M, Butler A, Chapman N, Frisk G, Gianani R, Giepmans B, von Herrath M, Hyoty H, Kay T, Korsgren O, Morgan NG, Pugliese A, Richardson SJ, Rowe P, Tracey S, In’t Veld P (2013). Position statement on the diagnosis of insulitis in type 1 diabetes. Diabetologia 56(11):2541-2543. <a href='http://www.ncbi.nlm.nih.gov/pubmed/24006089'>http://www.ncbi.nlm.nih.gov/pubmed/24006089</a></li>
                    <li>Richardson SJ, Morgan NG, Foulis AK (2014). Pancreatic Pathology in Type 1 Diabetes Mellitus. Endocrine Pathology 25(1):80-92. <a href='http://www.ncbi.nlm.nih.gov/pubmed/24522639'>http://www.ncbi.nlm.nih.gov/pubmed/24522639</a></li>
                    <li>Arif S, Leete P, Nguyen V, Marks K, Nor NM, Estorninho M, Kronenberg-Versteeg D, Bingley PJ, Todd JA, Guy C, Dunger DB, Powrie J, Willcox A, Foulis AK, Richardson SJ, de Rinaldis E, Morgan NG, Lorenc A, Peakman M (2014). Blood and islet phenotypes indicate immunological heterogeneity in type 1 diabetes. Diabetes 63(11):3835-3845. <a href='http://www.ncbi.nlm.nih.gov/pubmed/24939426'>http://www.ncbi.nlm.nih.gov/pubmed/24939426</a></li>
                    <li>Morgan NG, Richardson SJ (2014). Enteroviruses as causative agents in type 1 diabetes: loose ends or lost cause? Trends Endocrinol Metab. 25(12):611-619. <a href='http://www.ncbi.nlm.nih.gov/pubmed/25175301'>http://www.ncbi.nlm.nih.gov/pubmed/25175301</a></li>
                    <li>Morgan NG, Leete P, Foulis AK, Richardson SJ (2014). Islet inflammation in human type 1 diabetes mellitus. IUBMB Life 66(11):723-734. <a href='http://www.ncbi.nlm.nih.gov/pubmed/25504835'>http://www.ncbi.nlm.nih.gov/pubmed/25504835</a></li>
                    <li>Leete P, Krogvold L, Dahl-Jørgensen K, Foulis AK, Richardson SJ, Morgan NG (2016). Differential insulitic profiles determine the extent of beta cell destruction and the age at onset of type 1 diabetes. Diabetes 65(5):1362-1369. <a href='https://pubmed.ncbi.nlm.nih.gov/26858360'>https://pubmed.ncbi.nlm.nih.gov/26858360</a></li>
                    <li>Richardson SJ, Rodriguez-Calvo T, Gerlin IC, Mathews CE, Kaddis JS, Russell MA, Zeissler M, Leete P, Krogvold L, Dahl-Jorgensen K, von Herrath M, Pugliese A, Atkinson MA, Morgan NG (2016). Islet Cell Hyperexpression of HLA Class I Antigens: A Defining Feature in Type 1 Diabetes. Diabetologia 59(11):2448-2458. <a href='https://pubmed.ncbi.nlm.nih.gov/27506584'>https://pubmed.ncbi.nlm.nih.gov/27506584</a></li>
                    <li>Morgan NG, Richardson SJ. Fifty years of pancreatic islet pathology in human type 1 diabetes: insights gained and progress made (2018). Diabetologia 61(12):2499-2506. <a href='https://pubmed.ncbi.nlm.nih.gov/30255378'>https://pubmed.ncbi.nlm.nih.gov/30255378</a></li>
                    <li>Ifie E, Russell MA, Dhayal S, Leete P, Sebastiani G, Nigi L, Dotta F, Marjomäki V, Eizirik DL, Morgan NG, Richardson SJ (2018). Unexpected subcellular distribution of a specific isoform of the Coxsackie and adenovirus receptor, CAR-SIV, in human pancreatic beta cells. Diabetologia 61(11):2344-2355. <a href='https://pubmed.ncbi.nlm.nih.gov/30074059'>https://pubmed.ncbi.nlm.nih.gov/30074059</a></li>
                    <li>Colli MC, Hill JLE, Marroqui L, Chaffey J, Dos Santos RS, Leete P, Coomans de Brachene A, Paula FMM, Op de Beeck A, Castela A, Marselli L, Krogvold L, Dahl-Jorgensen K, Marchetti P, Morgan NG, Richardson SJ, Eizirik DL (2018). PDL1 is expressed in the islets of people with type 1 diabetes and is up-regulated by interferons-α and-γ via IRF1 induction. EbioMedicine 36:367-375. <a href='https://pubmed.ncbi.nlm.nih.gov/30269996'>https://pubmed.ncbi.nlm.nih.gov/30269996</a></li>
                    <li>Kronenberg-Versteeg D, Eichmann M, Russell MA, de Ru A, Hehn B, Yusuf N, van Veelen PA, Richardson SJ, Morgan NG, Lemberg MK, Peakman M (2018). Molecular Pathways for Immune Recognition of Preproinsulin Signal Peptide in Type 1 Diabetes. Diabetes 67(4):687-696. <a href='https://pubmed.ncbi.nlm.nih.gov/29343547'>https://pubmed.ncbi.nlm.nih.gov/29343547</a></li> 
                    <li>Russell MA, Redick SD, Blodgett DM, Richardson SJ, Leete P, Krogvold L, Dahl-Jørgensen K, Bottino R, Brissova M, Spaeth JM, Babon JAB, Haliyur R, Powers AC, Yang C, Kent SC, Derr AG, Kucukural A, Garber MG, Morgan NG, Harlan DM (2019). HLA Class II antigen processing and presentation pathway components demonstrated by transcriptome and protein analyses of islet β cells from donors with type 1 diabetes. Diabetes 68(5):988-1001. <a href='https://pubmed.ncbi.nlm.nih.gov/30833470'> https://pubmed.ncbi.nlm.nih.gov/30833470</a></li>
                    <li>Leete P, Oram RA, McDonald TJ, Shields BM, Ziller C, TIGI study team, Hattersley AT, Richardson SJ, Morgan NG (2020). Studies of insulin and proinsulin in pancreas and serum support the existence of aetiopathological endotypes of type 1 diabetes associated with age at diagnosis. Diabetologia 63:1258-1267. <a href='https://pubmed.ncbi.nlm.nih.gov/32172310'>https://pubmed.ncbi.nlm.nih.gov/32172310</a></li>
                    </ul>
                    </Collapse>
                  </div>: null  }            
              <Row className='my-4'>
                <Col md='12'>
                  <h3 className='mt-4'>Here are some suggested projections of the data within this set:</h3>
                </Col>
              </Row>
              <Row className='my-4'>
                {this.state.did === "531" ? <Col md='4'>
                  <Card className='h-100'>
                    <CardBody>
                      <h3>Browse by Age</h3>
                      <p>Choose a specific age range of donors within which to view samples</p>
                      <Link to={`/datasets/${this.state.did}/browse-by-age`}>
                        <Button>Browse</Button>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>: null}
                <Col md='4'>
                  <Card className='h-100'>
                    <CardBody>
                      <h3>Browse by Matrix</h3>
                      <p>Create a two-dimensional matrix comparing specified attribute sets to find data matching specific criteria</p>
                      <Link to={`/matrixview/${this.state.did}`}>
                        <Button>Browse</Button>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col md='4'>
                  <Card className='h-100'>
                    <CardBody>
                      <h3>View All Images</h3>
                      <p>Browse the entire collection without any filtering</p>
                      <Link to={`/datasets/${this.state.did}/explore`}>
                        <Button>Browse</Button>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col md='12'>
              <p dangerouslySetInnerHTML={{ __html: this.state.sponsors }} />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default withRouter(DatasetOverview)
